export enum CommitmentType {
  GRANT_CYCLE = 0,
  PROPOSAL_DIRECT = 1,
  ORGANIZATION_DIRECT = 2,
}

export enum ECommitmentReason {
  NONE = 0,
  GENERAL = 1,
  PROGRAM_SUPPORT = 2,
  OTHER = 3,
}
